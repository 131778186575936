
import { Component, Vue, Prop } from 'vue-property-decorator';
import ChartHeader from '@/views/StatisticsContent/ChartHeader.vue';
import { formatValueForDashboard } from '@/helpers/NumberFormatter';

@Component({
    name: 'TotalReport',
    components: {
        ChartHeader,
    },
})
export default class TotalReport extends Vue {
    @Prop({ required: true }) private data!: any;
    @Prop({ required: true }) private isEdit!: boolean;

    private get reportSize() {
        switch (this.data.size) {
            case 'S':
                return {
                    width: '48%',
                };
            default:
                return {
                    width: '100%',
                };
        }
    }

    private fontSize(element: string) {
        switch (this.data.size) {
            case 'S':
                return `total-report__${element}--font-small`;
            case 'L':
                return `total-report__${element}--font-large`;
            default:
                return '';
        }
    }

    private get shouldAdjustFontSize() {
        return this.data.size === 'L' || this.data.size === 'S';
    }

    private changeChartSize(size: 'S' | 'M' | 'L') {
        this.data.size = size;
    }
}
